<template>
  <CBox w="412px" minH="481px" bg="white" border="1px" borderColor="borderBox" fontFamily="Montserrat" mr="10px">
    <!-- <Loading v-if="!currentUser.emp_id" /> -->
    <CStack direction="row">
      <!-- <CImage w="75px" mt="24px" ml="26px" borderRadius="full" src="https://bit.ly/sage-adebayo"></CImage> -->
      <CAvatar v-if="!photoUrl" w="75px" h="75px" mt="24px" ml="26px" data-cy="ava1" />
      <CImage v-else w="75px" mt="24px" ml="26px" borderRadius="full" :src="'https://cdn.gethired.id/'+photoUrl"></CImage>
      <!-- <CAvatar v-else w="75px" h="75px" mt="24px" ml="26px" :src="'https://cdn.gethired.id/'+photoUrl" data-cy="ava2"> -->
      <!-- <CAvatarBadge size="1.0em" :style="{ backgroundImage: 'url(~@/assets/icon/crown.png)' }" /> -->
        <!-- <CAvatarBadge size="1.0em" bg="green.500" /> -->
        <!-- <CAvatarBadge size="1.0em" bgImage="url('../../../assets/icon/crown.png')" /> -->
        <!-- <CAvatarBadge size="1.0em" :src="require('@/assets/icon/crown.png')" /> -->
      <!-- </CAvatar> -->
      <CBox mt="24px" ml="20px">
        <CText textStyle="body" fontWeight="bolder" fontSize="20px" color="fontMain">{{ currentUser.emp_full_name }}</CText>
        <CText fontWeight="bolder" color="premiumColor" v-if="currentUser.is_premium && !currentUser.is_mentor">Member Premium</CText>
        <CText fontWeight="bolder" color="premiumColor" v-if="currentUser.is_mentor">Member Inspirator</CText>
        <CText fontSize="14px" fontWeight="light" color="fontMain" v-if="currentUser.is_premium && !currentUser.is_mentor">Berlaku hingga {{ currentUser.premium_end_str ? currentUser.premium_end_str : 'selamanya' }}</CText>
      </CBox>
    </CStack>
    <CBox mt="28px">
      <!-- <CBox h="58px" v-if="currentMenu == 'overview'" bg="activeMenu" class="pointer" @click.native="$router.push({ name: 'Dashboard Overview' })">
        <CBox ml="34px" h="inherit">
          <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
            <CBox d="table-cell" verticalAlign="middle" h="inherit">
              <CImage :src="require('@/assets/icon/icon-setting.png')" w="14px" h="14px" />
            </CBox>
            <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="15px">
              <CText color="white" fontWeight="bold">Overview</CText>
            </CBox>
          </CStack>
        </CBox>
      </CBox>
      <CBox h="58px" v-else class="pointer" @click.native="$router.push({ name: 'Dashboard Overview' })">
        <CBox ml="34px" h="inherit">
          <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
            <CBox d="table-cell" verticalAlign="middle" h="inherit">
              <CImage :src="require('@/assets/icon/icon-setting-blue.png')" w="14px" h="14px" />
            </CBox>
            <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="15px">
              <CText color="fontMain" fontWeight="bold">Overview</CText>
            </CBox>
          </CStack>
        </CBox>
      </CBox> -->
      <CBox h="58px" v-if="currentMenu == 'web-cv'" bg="activeMenu" class="pointer" @click.native="$router.push({ name: 'Dashboard Web CV' })">
        <CBox ml="34px" h="inherit">
          <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
            <CBox d="table-cell" verticalAlign="middle" h="inherit">
              <CImage :src="require('@/assets/icon/document-white.png')" w="14px" />
            </CBox>
            <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="15px">
              <CText fontWeight="bold" color="white">Web CV</CText>
            </CBox>
          </CStack>
        </CBox>
      </CBox>
      <CBox h="58px" v-else class="pointer" @click.native="$router.push({ name: 'Dashboard Web CV' })">
        <CBox ml="34px" h="inherit">
          <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
            <CBox d="table-cell" verticalAlign="middle" h="inherit">
              <CImage :src="require('@/assets/icon/document.png')" w="14px" />
            </CBox>
            <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="15px">
              <CText fontWeight="bold" color="fontMain">Web CV</CText>
            </CBox>
          </CStack>
        </CBox>
      </CBox>
      <CBox h="58px" v-if="currentMenu == 'pesan-pengunjung'" bg="activeMenu" class="pointer" @click.native="$router.push({ name: 'Dashboard Pesan Pengunjung' })">
        <CBox ml="34px" h="inherit">
          <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
            <CBox d="table-cell" verticalAlign="middle" h="inherit">
              <CImage :src="require('@/assets/icon/interaction-white.png')" w="14px" />
            </CBox>
            <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="15px">
              <CText fontWeight="bold" color="white">Pesan Pengunjung</CText>
            </CBox>
          </CStack>
        </CBox>
      </CBox>
      <CBox h="58px" v-else class="pointer" @click.native="$router.push({ name: 'Dashboard Pesan Pengunjung' })">
        <CBox ml="34px" h="inherit">
          <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
            <CBox d="table-cell" verticalAlign="middle" h="inherit">
              <CImage :src="require('@/assets/icon/interaction.png')" w="14px" />
            </CBox>
            <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="15px">
              <CText fontWeight="bold" color="fontMain">Pesan Pengunjung</CText>
            </CBox>
          </CStack>
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
// import Loading from "@/components/Loading.vue";

export default {
  // props: ["currentUser"],
  props: {
    currentUser: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    // Loading
  },
  data() {
    return {
      photoUrl: "",
    }
  },
  computed: {
    currentMenu() {
      let menu = "overview"
      const webCV = ["Dashboard Web CV", "Choose Web CV"];
      const pengunjung = ["Dashboard Pesan Pengunjung"]
      if (webCV.includes(this.$route.name)) menu = "web-cv"
      if (pengunjung.includes(this.$route.name)) menu = "pesan-pengunjung"
      return menu
    }
  },
  watch: {
    currentUser: {
      deep: true,
      handler() {
        if (this.currentUser.emp_photo) this.photoUrl = this.currentUser.emp_photo;
      }
    }
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
