<template>
  <div>
    <Loading :active="isLoading" :is-full-page="true" />
    <HeaderDashboard :currentUser="currentUser" />
    <CBox bg="bgMain" h="100%" minHeight="calc(100vh - 54px)">
      <CBox pt="5px" pb="5px" maxW="1080px" mx="auto">
        <HeaderPremium />
        <Breadcrumb />
        <CGrid :gap="3" mt="20px" mb="50px" templateColumns="1fr 3fr">
          <SidebarDashboard :currentUser="currentUser" />
          <router-view :currentUser="currentUser" />
        </CGrid>
      </CBox>
    </CBox>
  </div>
</template>

<script>
import HeaderDashboard from '@/components/header/HeaderDashboard.vue'
import { GET_USER, PURGE_AUTH } from "@/store/auth.module"
import config from "@/ConfigProvider";
import SidebarDashboard from "@/components/sidebar/SidebarDashboard.vue"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import HeaderPremium from "@/components/header/HeaderPremium.vue"
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue"

export default {
  name: "LayoutDashboard",
  components: {
    HeaderDashboard,
    SidebarDashboard,
    Loading,
    HeaderPremium,
    Breadcrumb
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    if (!this.$route.query.payment && this.$route.query.payment !== "success") {
      this.getData();
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.$store.dispatch(GET_USER);
      let status = false;

      if (this.currentUser.is_premium && !this.currentUser.premium_end) {
        status = true;
      } else if (this.currentUser.premium_end && new Date().getTime() < new Date(this.currentUser.premium_end).getTime()) {
        status = true;
      } else if (this.currentUser.trial_end && new Date().getTime() < new Date(this.currentUser.trial_end).getTime()) {
        status = true;
      } else if (this.currentUser.is_mentor) {
        status = true;
      }

      if (!status) {
        this.$store.commit(PURGE_AUTH);
        window.location.href = config.value("FE_URL")+"/employee/profile";
      } else {
        this.isLoading = false;
      }
    }
  }
}
</script>

